$(document).ready(() => {

    // AOS
    AOS.init({
        once: true
    });
    

    // Slides height fix
    function heighFix() {
        const slides = $('.client').find(".slick-slide");
        let maxHeight = 0;
      
        slides.each(function () {
            maxHeight = Math.max(maxHeight, $(this).height());
        });

        slides.each(function () {
            $(this).height(maxHeight);
            $(this).find("div").first().height(maxHeight);
        });
    }

     // Scroll function 
     function scrollWindow(offset, interval) {
        $('html, body').animate({
            scrollTop: offset
        }, interval, () => {
            return false;
        });
    }

    $(".intro-cta").click(() => {
        scrollWindow($("#about").offset().top - 100, 500);
    });

    // Contact Form Code
    const contact_form = $('.contact-form');

    contact_form.submit(function (e) {

        var form_data = {};
        contact_form.serializeArray().map(function (x) {
            if (x.name) {
                form_data[x.name] = x.value;
            }
        });

        $.get('http://localhost:5000/contact', form_data, function (data) {
            $.ajax({
                dataType: 'jsonp',
                url: "http://getsimpleform.com/messages/ajax?form_api_token=2e7d9f212326bfddc4c57927f27dd911",
                data: form_data
            }).done(() => {
                location.reload();
            });
        });
        e.preventDefault();
    });

    // SLICK Slider

    $('.client').on('init', function (event, slick) {
        heighFix();
    });

    $('.client').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 1500,
        adaptiveHeight: true,
        arrows: false,
        dots: true,
        pauseOnHover: false,
        adaptiveHeight: true,
        responsive: [{
            breakpoint: 768,
            settings: {
                slidesToShow: 1
            }
        }, {
            breakpoint: 520,
            settings: {
                slidesToShow: 1
            }
        }]
    });


    $('.customer').slick({
        slidesToShow: 6,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 1500,
        arrows: false,
        dots: false,
        pauseOnHover: false,
        responsive: [{
            breakpoint: 768,
            settings: {
                slidesToShow: 2
            }
        }, {
            breakpoint: 520,
            settings: {
                slidesToShow: 1
            }
        }]
    });

    // Scroll SPY
    $("#nav ul li a[href^='#']").on('click', function (e) {
        // prevent default anchor click behavior
        e.preventDefault();
        scrollWindow($(this.hash).offset().top, 600);
    });

    
    $('#carousel').slick({
        autoplay: false,
        fade: true,
        arrows: false,
        infinite: false,
        speed: 1000,
        responsive: [{
            breakpoint: 767,
            settings: {
                autoplay: true,
                autoplaySpeed: 3000
            }
        }]
    });

    $('#carousel').on('beforeChange', (event, slick, currentSlide, nextSlide) => {
        if (currentSlide * nextSlide === 0) {
            $(".slider-control-prev").toggleClass("btn-disabled");
        }
        if (currentSlide * nextSlide === 2) {
            $(".slider-control-next").toggleClass("btn-disabled");
        }
    });

    $('.slider-control-prev').click((e) => {
        e.preventDefault();
        $('#carousel').slick('slickPrev');
    });

    $('.slider-control-next').click((e) => {
        e.preventDefault();
        $('#carousel').slick('slickNext');
    });

})

// Map Code
let map;
const mapElement = document.getElementById('map');


function initMap() {

    const latitude = +$("#map").data('latitude');
    const longitude = +$("#map").data('longitude');
    map = new google.maps.Map(mapElement, {
        // How zoomed in you want the map to start at (always required)
        zoom: 11,
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        gestureHandling: 'none',
        // The latitude and longitude to center the map (always required)
        center: new google.maps.LatLng(latitude, longitude), // New York

        // How you would like to style the map. 
        // This is where you would paste any style found on Snazzy Maps.
        styles: [{
            "featureType": "administrative",
            "elementType": "labels.text.fill",
            "stylers": [{
                "color": "#444444"
            }]
        }, {
            "featureType": "administrative.land_parcel",
            "elementType": "all",
            "stylers": [{
                "visibility": "off"
            }]
        }, {
            "featureType": "landscape",
            "elementType": "all",
            "stylers": [{
                "color": "#f2f2f2"
            }]
        }, {
            "featureType": "landscape.natural",
            "elementType": "all",
            "stylers": [{
                "visibility": "off"
            }]
        }, {
            "featureType": "poi",
            "elementType": "all",
            "stylers": [{
                "visibility": "on"
            }, {
                "color": "#052366"
            }, {
                "saturation": "-70"
            }, {
                "lightness": "85"
            }]
        }, {
            "featureType": "poi",
            "elementType": "labels",
            "stylers": [{
                "visibility": "simplified"
            }, {
                "lightness": "-53"
            }, {
                "weight": "1.00"
            }, {
                "gamma": "0.98"
            }]
        }, {
            "featureType": "poi",
            "elementType": "labels.icon",
            "stylers": [{
                "visibility": "simplified"
            }]
        }, {
            "featureType": "road",
            "elementType": "all",
            "stylers": [{
                "saturation": -100
            }, {
                "lightness": 45
            }, {
                "visibility": "on"
            }]
        }, {
            "featureType": "road",
            "elementType": "geometry",
            "stylers": [{
                "saturation": "-18"
            }]
        }, {
            "featureType": "road",
            "elementType": "labels",
            "stylers": [{
                "visibility": "off"
            }]
        }, {
            "featureType": "road.highway",
            "elementType": "all",
            "stylers": [{
                "visibility": "on"
            }]
        }, {
            "featureType": "road.arterial",
            "elementType": "all",
            "stylers": [{
                "visibility": "on"
            }]
        }, {
            "featureType": "road.arterial",
            "elementType": "labels.icon",
            "stylers": [{
                "visibility": "off"
            }]
        }, {
            "featureType": "road.local",
            "elementType": "all",
            "stylers": [{
                "visibility": "on"
            }]
        }, {
            "featureType": "transit",
            "elementType": "all",
            "stylers": [{
                "visibility": "off"
            }]
        }, {
            "featureType": "water",
            "elementType": "all",
            "stylers": [{
                "color": "#57677a"
            }, {
                "visibility": "on"
            }]
        }]
    });

    const marker = new google.maps.Marker({
        position: new google.maps.LatLng(latitude, longitude),
        map: map
    });

}